/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, SLSD){
	const a = 'is-active';
	const $map = location.pathname;
	const mapSplit = location.pathname.split('/');
	const $selectY = $('.mod-select select');
	const $selectYLabel = $('.mod-select-label');
	const $tabItem = $('.mod-tabChange-btn');
	const $output = $('.js-news-output');
	const cmsLatestY = parseInt($('.rt_bn_latest_news .rt_latest_year').text());
	const oldestY = 2009;
	let $currentCat = mapSplit[mapSplit.length -2];
	let urlHead;
	let langPronexusUrl;
	let $currentY;

	// ＜共通＞JSON取得 ---------------------------------------------------------------------------
	// CMSoD上最新5件のIRニュース取得用
	const getJSONlatestNewsList = (_urlHead) => {
		return $.getJSON(`/ajax${_urlHead}ir.json`);
	};
	// CMSoD上のニュース全件（日）
	const getJSONnewsList = () => {
		return $.getJSON('/ajax/news/all.json');
	};
	// CMSoD上の西暦ごとのニュースの取得用
	const getJSONallNewsList = (_urlHead, _year) => {
		return $.getJSON(`/ajax${_urlHead}${_year}/all.json`);
	};
	// CMSoD上の西暦ごとのIRニュースの取得用
	const getJSONirNewsList = (_urlHead, _year) => {
		return $.getJSON(`/ajax${_urlHead}${_year}/ir.json`);
	};
	// プロネクサス側のjsonp取得用
	const getJSONpronexusNewsList = () => {
		return $.ajax({
			type: 'GET',
			url: 'https://ssl4.eir-parts.net/V4Public/eir/7776/ja/announcement/announcement_0.js',
			dataType: 'jsonp',
			jsonp: 'callback',
			jsonpCallback: 'eolparts_announcement_0'
		});
	};

	// <共通>----------------------------------------------------------------------------------------
	// 現在日時
	const thisDate = new Date();
	// ニュースの要素仮定義
	let newsDate,
		newsCat,
		newsAnchor,
		newsType,
		newsCatTag,
		newsTit,
		newsFileSize,
		newsFileExt,
		newsFileIcon,
		newsNewFlag;

	// ニュースラベル
	const labelIr = '<span class="mod-newsList-item-tag">IR</span>';

	// ニュース出力タグ
	let newsHTMLcreate = (_anchor, _date, _tag, _tit, _fileIcon) => {
		$(`<li class="mod-newsList-item">
			${ _anchor}
				<time class="mod-newsList-item-date">${_date}</time>
				${_tag}
				<span class="mod-newsList-item-tit">${_tit}
					${_fileIcon}
				</span>
			</a>
		</li>`).appendTo($output);
	};

	// ファイルサイズ変換
	// プロネクサスデータ用（KB、「,」有り）
	const bytesToSizePronexus = (bytes) => {
		const sizes = ['KB', 'MB', 'GB'];
		if (bytes.replace(/,/g, '') === 0) return '0 Byte';
		let i = parseInt(Math.floor(Math.log(bytes.replace(/,/g, '')) / Math.log(1024)));
		return Math.floor(bytes.replace(/,/g, '') / Math.pow(1024, i)* Math.pow(10, 1)) / Math.pow(10, 1) + sizes[i];
	};
	// CMSoDデータ用（byte、「,」無し）
	const bytesToSize = (bytes) => {
		const sizes = ['Bytes', 'KB', 'MB', 'GB'];
		if (bytes === 0) return '0 Byte';
		let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		let convSize = Math.floor(bytes / Math.pow(1024, i)* Math.pow(10, 1)) / Math.pow(10, 1);
		// KBのとき、小数点切り捨て
		if (i === 1) convSize = Math.floor(convSize);
		return convSize + sizes[i];
	};
	// 日付における1桁の数字を0埋めで2桁にする
	const toDoubleDigits = function (num) {
		num += '';
		if (num.length === 1) {
			num = '0' + num;
		}
		return num;
	};

	// 言語別出し分け
	if ($map.split('/')[1] === 'en') { // 英語
		$currentY = mapSplit[3];
		urlHead = '/en/news/';
		// langPronexusUrl = 'https://ssl4.eir-parts.net/V4Public/eir/7776/ja/announcement/announcement_0.js';
	} else { // 日本語
		$currentY = mapSplit[2];
		urlHead = '/news/';
		// langPronexusUrl = 'https://ssl4.eir-parts.net/V4Public/eir/7776/ja/announcement/announcement_0.js';
	}

	// -------------------------------------------------------------------------------------------------------
	// ＜リダイレクト＞/news/ or /en/news/にアクセスした際は、最新年号ニュース一覧へ飛ばす
	if ($map === '/news/' || $map === '/news/index.html' || $map === '/en/news/' || $map === '/en/news/index.html') {

		// 最新年号
		getJSONpronexusNewsList().done((data) => {
			let pronexusNewsList = data.item;
			let pronexusLatestY = 0;
			if ($map.split('/')[1] !== 'en') {
				pronexusLatestY = pronexusNewsList[0].format_date.split('/')[0];
			}
			let latestYear = Math.max(pronexusLatestY, cmsLatestY);
			location.href = `${urlHead}${latestYear}/`;
		}).fail(function (jqXHR, textStatus, errorThrown){
			location.href = `${urlHead}${cmsLatestY}/`;
		});

	} else {
		$('.rt_bn_news_list_is_not_0').removeClass('di_none');
		$('.rt_bn_news_list_is_0').removeClass('di_none');
		$('.mod-topicPath-li').removeClass('di_none');
		$('.mod-select').removeClass('di_none');
		$('.mod-tabChange-wrap').removeClass('di_none');
	}

	// トップページ（日のみ）----------------------------------------------------------------------------------------
	if ($map === '/' || $map === '/index.html') {
		getJSONpronexusNewsList().done((data) => {
			let pronexusNewsList = data.item;
			// 最新5件を描画
			let latestNewsArr = [];
			let latestIrNews5 = pronexusNewsList.splice(0, 5);
			for (let i=0; i<latestIrNews5.length; i++) {
				let thisDate = latestIrNews5[i].date.replace(/[^0-9]/g, '');
				let thisId = latestIrNews5[i].guid;
				latestNewsArr.push({ id:thisId, datetime:thisDate });
			}

			getJSONnewsList().done((dataCms) => {
				let cmsNewsList = dataCms.rt_bn_news_list;
				for (let i=0; i<cmsNewsList.length; i++) {
					let date = new Date(cmsNewsList[i].cf_n_date);
					let thisDate = date.getFullYear() +''+ toDoubleDigits(date.getMonth()+1) +''+ toDoubleDigits(date.getDate()) +'';
					let thisId = cmsNewsList[i]._key;
					latestNewsArr.push({ id:thisId, datetime:thisDate });
				}

				// 各新着5件(合計10件)を統合して日付順にソート
				latestNewsArr.sort(
					function (x, y) {
						let xDate = x['datetime'];
						let yDate = y['datetime'];
						if (xDate > yDate) return(-1);
						if (xDate < yDate) return(1);
						return 0;
					}
				);

				// 統合後の最新5件を描画
				const latestNewsArr5 = latestNewsArr.splice(0, 5);
				// IRニュース一覧へのボタンリンク
				$('.js-btn-allNews').attr('href', `${urlHead}${cmsLatestY}/`);

				for (let i=0; i<latestNewsArr5.length; i++) {
					// プロネクサス
					for (let j=0; j<latestIrNews5.length; j++) {
						if (latestIrNews5[j].guid === latestNewsArr5[i].id) {
							newsDate = latestIrNews5[j].format_date.replace(/\//g, '.');
							newsCatTag = labelIr;
							newsAnchor = `<a href="${latestIrNews5[j].link}" target="${latestIrNews5[j].new_target}" class=mod-newsList-item-a>`;
							newsTit = latestIrNews5[j].title;
							newsFileSize = bytesToSizePronexus(latestIrNews5[j].file_size);
							newsFileExt = latestIrNews5[j].type;

							// newsFileIcon出し分け
							if (newsFileExt === 'pdf') {
								newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-pdf"></i>'+
									`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
							} else {
								newsFileIcon = `<span>[${newsFileSize}]</span>`;
							}

							// ニュース描画
							newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
						}
					}

					// CMSoD
					for (let k=0; k<cmsNewsList.length; k++) {
						let _key = cmsNewsList[k]._key;
						if (_key === latestNewsArr5[i].id) {
							// UTCからJSTに変換
							let date = new Date(cmsNewsList[k].cf_n_date);
							let newsDate = date.getFullYear() +'.'+ toDoubleDigits(date.getMonth()+1) +'.'+ toDoubleDigits(date.getDate());
							newsTit = cmsNewsList[k].cf_n_title;
							newsType = 	cmsNewsList[k].cf_n_type;
							let newsCatCode = cmsNewsList[k].cf_n_category.cf_nc_code;
							let newsCatTxt;
							if ($map.split('/')[1] === 'en') {
								newsCatTxt = cmsNewsList[k].cf_n_category.cf_nc_name_en;
							} else {
								newsCatTxt = cmsNewsList[k].cf_n_category.cf_nc_name;
							}
							newsCatTag = `<span class="mod-newsList-item-tag">${newsCatTxt}</span>`;

							if (newsType === 1) {
								// フリーレイアウト
								newsFileIcon = '';
								newsAnchor = `<a href="${urlHead}${newsDate.split('.')[0]}/${newsCatCode}/${_key}.html" class="mod-newsList-item-a">`;
							} else if (newsType === 2) {
								// 添付ファイル
								let dataFile = cmsNewsList[k].cf_n_attachment_file.replace(/\ /g, '');
								newsFileSize = bytesToSize(cmsNewsList[k].cf_n_attachment_file_size);
								newsFileExt = dataFile.split('.')[dataFile.split('.').length -1];
								newsAnchor = `<a href="/news/news_file/file/${dataFile}" target="_blank" class="mod-newsList-item-a">`;

								if (newsFileExt === 'pdf') {
									newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-pdf"></i>'+
										`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
								} else {
									newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-blank"></i>'+
										`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
								}

							} else if (newsType === 3) {
								// URL指定
								newsFileIcon = '';
								let newsUrl = cmsNewsList[k].cf_n_url;
								if (newsUrl.indexOf('https://') !== -1 || newsUrl.indexOf('http://') !== -1) {
									newsAnchor = `<a href="${newsUrl}" target="_blank" class="mod-newsList-item-a">`;
								} else {
									newsAnchor = `<a href="${newsUrl}" class="mod-newsList-item-a">`;
								}
							} else if (newsType === 5) {
								// テキスト表示
								newsFileIcon = '';
								newsAnchor = '<a href="" style="pointer-events:none;cursor:default;text-decoration:none;" class="mod-newsList-item-a">';
							}

							// ニュース描画
							newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
						}
					}
				}
			});
		});
	}

	// IRトップ&IRLibraryトップ --------------------------------------------------------------------
	if ($map === '/ir/' || $map === '/ir/index.html' ||
		$map === '/ir/library/' || $map === '/ir/library/index.html' ||
		$map === '/sitemap/' || $map === '/sitemap/index.html' ||
		$map === '/en/' || $map === '/en/index.html' ||
		$map === '/en/ir/' || $map === '/en/ir/index.html' ||
		$map === '/en/ir/library/' || $map === '/en/ir/library/index.html' ||
		$map === '/en/sitemap/' || $map === '/en/sitemap/index.html') {
		getJSONpronexusNewsList().done((data) => {
			let pronexusNewsList = data.item;
			// 最新5件を描画
			let latestNewsArr = [];
			let latestIrNews5 = pronexusNewsList.splice(0, 5);
			for (let i=0; i<latestIrNews5.length; i++) {
				let thisDate = latestIrNews5[i].date.replace(/[^0-9]/g, '');
				let thisId = latestIrNews5[i].guid;
				if ($map.split('/')[1] !== 'en') {
					latestNewsArr.push({ id:thisId, datetime:thisDate });
				}
			}
			getJSONlatestNewsList(urlHead).done((dataCms) => {
				let cmsNewsList = dataCms.rt_bn_news_list;
				for (let i=0; i<cmsNewsList.length; i++) {
					let date = new Date(cmsNewsList[i].cf_n_date);
					let thisDate = date.getFullYear() +''+ toDoubleDigits(date.getMonth()+1) +''+ toDoubleDigits(date.getDate()) +'';
					let thisId = cmsNewsList[i]._key;
					latestNewsArr.push({ id:thisId, datetime:thisDate });
				}

				// 各新着5件(合計10件)を統合して日付順にソート
				latestNewsArr.sort(
					function (x, y) {
						let xDate = x['datetime'];
						let yDate = y['datetime'];
						if (xDate > yDate) return(-1);
						if (xDate < yDate) return(1);
						return 0;
					}
				);

				// 統合後の最新5件を描画
				const latestNewsArr5 = latestNewsArr.splice(0, 5);
				// IRニュース一覧へのボタンリンク
				$('.js-btn-irNews').attr('href', `${urlHead}${latestNewsArr5[0].datetime.substr(0, 4)}/ir/`);
				$('.js-btn-allNews').attr('href', `${urlHead}${cmsLatestY}/`);

				for (let i=0; i<latestNewsArr5.length; i++) {
					// プロネクサス
					for (let j=0; j<latestIrNews5.length; j++) {
						if (latestIrNews5[j].guid === latestNewsArr5[i].id) {
							newsDate = latestIrNews5[j].format_date.replace(/\//g, '.');
							newsCatTag = labelIr;
							newsAnchor = `<a href="${latestIrNews5[j].link}" target="${latestIrNews5[j].new_target}" class=mod-newsList-item-a>`;
							newsTit = latestIrNews5[j].title;
							newsFileSize = bytesToSizePronexus(latestIrNews5[j].file_size);
							newsFileExt = latestIrNews5[j].type;

							// newsFileIcon出し分け
							if (newsFileExt === 'pdf') {
								newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-pdf"></i>'+
									`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
							} else {
								newsFileIcon = `<span>[${newsFileSize}]</span>`;
							}

							// ニュース描画
							newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
						}
					}

					// CMSoD
					for (let k=0; k<cmsNewsList.length; k++) {
						let _key = cmsNewsList[k]._key;
						if (_key === latestNewsArr5[i].id) {
							// UTCからJSTに変換
							let date = new Date(cmsNewsList[k].cf_n_date);
							let newsDate = date.getFullYear() +'.'+ toDoubleDigits(date.getMonth()+1) +'.'+ toDoubleDigits(date.getDate());
							newsTit = cmsNewsList[k].cf_n_title;
							newsType = 	cmsNewsList[k].cf_n_type;
							newsCatTag = labelIr;

							if (newsType === 1) {
								// フリーレイアウト
								newsFileIcon = '';
								newsAnchor = `<a href="${urlHead}${newsDate.split('.')[0]}/ir/${_key}.html" class="mod-newsList-item-a">`;
							} else if (newsType === 2) {
								// 添付ファイル
								let dataFile = cmsNewsList[k].cf_n_attachment_file;
								newsFileSize = bytesToSize(cmsNewsList[k].cf_n_attachment_file_size);
								newsFileExt = dataFile.split('.')[dataFile.split('.').length -1];
								newsAnchor = `<a href="/news/news_file/file/${dataFile}" target="_blank" class="mod-newsList-item-a">`;

								if (newsFileExt === 'pdf') {
									newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-pdf"></i>'+
										`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
								} else {
									newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-blank"></i>'+
										`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
								}

							} else if (newsType === 3) {
								// URL指定
								newsFileIcon = '';
								let newsUrl = cmsNewsList[k].cf_n_url;
								if (newsUrl.indexOf('https://') !== -1 || newsUrl.indexOf('http://') !== -1) {
									newsAnchor = `<a href="${newsUrl}" target="_blank" class="mod-newsList-item-a">`;
								} else {
									newsAnchor = `<a href="${newsUrl}" class="mod-newsList-item-a">`;
								}
							} else if (newsType === 5) {
								// テキスト表示
								newsFileIcon = '';
								newsAnchor = '<a href="" style="pointer-events:none;cursor:default;text-decoration:none;" class="mod-newsList-item-a">';
							}

							// ニュース描画
							newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
						}
					}
				}
			});
		});
	}

	// --------------------------------------------------------------------------------------------------------
	// <西暦ニュース一覧> -----------------------------------------------------------------------------------------
	if ($selectY.length) {
		if ($currentCat === $currentY) {
			$currentCat = 'all';
		}
		// 最新年号
		getJSONpronexusNewsList().done((data) => {
			let pronexusNewsList = data.item;
			let pronexusLatestY = pronexusNewsList[0].format_date.split('/')[0];
			if ($map.split('/')[1] === 'en') {
				pronexusLatestY = 0;
			}
			let latestYear = Math.max(pronexusLatestY, cmsLatestY);
			// 年号セレクトボックス生成
			for (let i = oldestY; i < latestYear+1; i++) {
				// 英語のニュースは2009、2012が0件のためスキップ
				if ($map.indexOf('/en/news/') !== -1 && (i === 2012 || i === 2009)) {
					continue;
				} else {
					$selectY.prepend(`<option value="${i}">${i}</option>`);
				}
			}
			$selectY.val($currentY)
			$selectYLabel.text($currentY);

			// 年号ごとのニュース一覧------------------------------------------
			if ($currentCat === 'all') {
				$('.rt_bn_news_list_is_not_0').remove();
				$('.rt_bn_news_list_is_0').addClass('di_none');

				let allNewsArr = [];

				for (let i=0; i<pronexusNewsList.length; i++) {
					if (pronexusNewsList[i].date.split('/')[0] === $currentY) {
						let thisDate = pronexusNewsList[i].date.replace(/[^0-9]/g, '');
						let thisId = pronexusNewsList[i].guid;
						if ($map.split('/')[1] !== 'en') {
							allNewsArr.push({ id:thisId, datetime:thisDate });
						}
					}
				}
				// プロネクサスのニュースが1件でもあった場合0件メッセージ削除
				if (allNewsArr.length === 0) {
					$('.rt_bn_news_list_is_0').removeClass('di_none');
				}

				getJSONallNewsList(urlHead, $currentY).done((dataCms) => {
					let cmsNewsList = dataCms.rt_bn_news_list;

					for (let i=0; i<cmsNewsList.length; i++) {
						// UTCからJSTに変換
						let date = new Date(cmsNewsList[i].cf_n_date);
						let thisDate = date.getFullYear() +''+ toDoubleDigits(date.getMonth()+1) +''+ toDoubleDigits(date.getDate()) +'';
						let thisId = cmsNewsList[i]._key;
						allNewsArr.push({ id:thisId, datetime:thisDate });
					}

					// 統合して日付順にソート
					allNewsArr.sort(
						function (x, y) {
							let xDate = x['datetime'];
							let yDate = y['datetime'];
							if (xDate > yDate) return(-1);
							if (xDate < yDate) return(1);
							return 0;
						}
					);

					for (let i=0; i<allNewsArr.length; i++) {
						// プロネクサス
						for (let j=0; j<pronexusNewsList.length; j++) {
							if (pronexusNewsList[j].guid === allNewsArr[i].id) {
								newsDate = pronexusNewsList[j].format_date.replace(/\//g, '.');
								newsCatTag = labelIr;
								newsAnchor = `<a href="${pronexusNewsList[j].link}" target="${pronexusNewsList[j].new_target}" class=mod-newsList-item-a>`;
								newsTit = pronexusNewsList[j].title;
								newsFileSize = bytesToSizePronexus(pronexusNewsList[j].file_size);
								newsFileExt = pronexusNewsList[j].type;

								// newsFileIcon出し分け
								if (newsFileExt === 'pdf') {
									newsFileIcon = `<span class="mod-newsList-item-file">
									<i class="icon icon-pdf"></i>
									<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span>
									</span>`;
								} else {
									newsFileIcon = `<span class="mod-newsList-item-file">
									<i class="icon icon-blank"></i>
									<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span>
									</span>`;
								}

								// ニュース描画
								newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
							}
						}

						// CMSoD
						for (let k=0; k<cmsNewsList.length; k++) {
							let _key = cmsNewsList[k]._key;
							if (_key === allNewsArr[i].id) {
								// UTCからJSTに変換
								let date = new Date(cmsNewsList[k].cf_n_date);
								let newsDate = date.getFullYear() +'.'+ toDoubleDigits(date.getMonth()+1) +'.'+ toDoubleDigits(date.getDate());
								newsTit = cmsNewsList[k].cf_n_title;
								newsType = 	cmsNewsList[k].cf_n_type;
								let newsCatCode = cmsNewsList[k].cf_n_category.cf_nc_code;
								let newsCatTxt;
								if ($map.split('/')[1] === 'en') {
									newsCatTxt = cmsNewsList[k].cf_n_category.cf_nc_name_en;
								} else {
									newsCatTxt = cmsNewsList[k].cf_n_category.cf_nc_name;
								}
								newsCatTag = `<span class="mod-newsList-item-tag">${newsCatTxt}</span>`;

								if (newsType === 1) {
									// フリーレイアウト
									newsFileIcon = '';
									newsAnchor = `<a href="${urlHead}${newsDate.split('.')[0]}/${newsCatCode}/${_key}.html" class="mod-newsList-item-a">`;
								} else if (newsType === 2) {
									// 添付ファイル
									let dataFile = cmsNewsList[k].cf_n_attachment_file.replace(/\ /g, '');
									newsFileSize = bytesToSize(cmsNewsList[k].cf_n_attachment_file_size);
									//console.log('newsFileSize', parseInt(newsFileSize, 10));
									newsFileExt = dataFile.split('.')[dataFile.split('.').length -1];
									newsAnchor = `<a href="/news/news_file/file/${dataFile}" target="_blank" class="mod-newsList-item-a">`;

									if (newsFileExt === 'pdf') {
										newsFileIcon = `<span class="mod-newsList-item-file">
										<i class="icon icon-pdf"></i>
										<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span>
										</span>`;
									} else {
										newsFileIcon = `<span class="mod-newsList-item-file">
										<i class="icon icon-blank"></i>
										<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span>
										</span>`;
									}

								} else if (newsType === 3) {
									// URL指定
									newsFileIcon = '';
									let newsUrl = cmsNewsList[k].cf_n_url;
									if (newsUrl.indexOf('https://') !== -1 || newsUrl.indexOf('http://') !== -1) {
										newsAnchor = `<a href="${newsUrl}" target="_blank" class="mod-newsList-item-a">`;
									} else {
										newsAnchor = `<a href="${newsUrl}" class="mod-newsList-item-a">`;
									}
								} else if (newsType === 5) {
									// テキスト表示
									newsFileIcon = '';
									newsAnchor = '<a href="" style="pointer-events:none;cursor:default;text-decoration:none;" class="mod-newsList-item-a">';
								}

								// ニュース描画
								newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
							}
						}
					}

				});
			} else if ($currentCat === 'ir') {
				$('.rt_bn_news_list_is_not_0').remove();
				$('.rt_bn_news_list_is_0').addClass('di_none');

				let allNewsArr = [];

				for (let i=0; i<pronexusNewsList.length; i++) {
					if (pronexusNewsList[i].date.split('/')[0] === $currentY) {
						let thisDate = pronexusNewsList[i].date.replace(/[^0-9]/g, '');
						let thisId = pronexusNewsList[i].guid;
						if ($map.split('/')[1] !== 'en') {
							allNewsArr.push({ id:thisId, datetime:thisDate });
						}
					}
				}

				if (allNewsArr.length === 0) {
					$('.rt_bn_news_list_is_0').removeClass('di_none');
				}

				getJSONirNewsList(urlHead, $currentY).done((dataCms) => {
					let cmsNewsList = dataCms.rt_bn_news_list;

					for (let i=0; i<cmsNewsList.length; i++) {
						// UTCからJSTに変換
						let date = new Date(cmsNewsList[i].cf_n_date);
						let thisDate = date.getFullYear() +''+ toDoubleDigits(date.getMonth()+1) +''+ toDoubleDigits(date.getDate()) +'';
						let thisId = cmsNewsList[i]._key;
						allNewsArr.push({ id:thisId, datetime:thisDate });
					}

					// 統合して日付順にソート
					allNewsArr.sort(
						function (x, y) {
							let xDate = x['datetime'];
							let yDate = y['datetime'];
							if (xDate > yDate) return(-1);
							if (xDate < yDate) return(1);
							return 0;
						}
					);

					for (let i=0; i<allNewsArr.length; i++) {
						// プロネクサス
						for (let j=0; j<pronexusNewsList.length; j++) {
							if (pronexusNewsList[j].guid === allNewsArr[i].id) {
								newsDate = pronexusNewsList[j].format_date.replace(/\//g, '.');
								newsCatTag = labelIr;
								newsAnchor = `<a href="${pronexusNewsList[j].link}" target="${pronexusNewsList[j].new_target}" class=mod-newsList-item-a>`;
								newsTit = pronexusNewsList[j].title;
								newsFileSize = bytesToSizePronexus(pronexusNewsList[j].file_size);
								newsFileExt = pronexusNewsList[j].type;

								// newsFileIcon出し分け
								if (newsFileExt === 'pdf') {
									newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-pdf"></i>'+
										`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
								} else {
									newsFileIcon = '<span class="mod-newsList-item-file">'+
										'<i class="icon icon-blank"></i>'+
										`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
								}

								// ニュース描画
								newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
							}
						}

						// CMSoD
						for (let k=0; k<cmsNewsList.length; k++) {
							let _key = cmsNewsList[k]._key;
							if (_key === allNewsArr[i].id) {
								// UTCからJSTに変換
								let date = new Date(cmsNewsList[k].cf_n_date);
								let newsDate = date.getFullYear() +'.'+ toDoubleDigits(date.getMonth()+1) +'.'+ toDoubleDigits(date.getDate());
								newsTit = cmsNewsList[k].cf_n_title;
								newsType = 	cmsNewsList[k].cf_n_type;
								let newsCatCode = cmsNewsList[k].cf_n_category.cf_nc_code;
								let newsCatTxt;
								if ($map.split('/')[1] === 'en') {
									newsCatTxt = cmsNewsList[k].cf_n_category.cf_nc_name_en;
								} else {
									newsCatTxt = cmsNewsList[k].cf_n_category.cf_nc_name;
								}
								newsCatTag = `<span class="mod-newsList-item-tag">${newsCatTxt}</span>`;

								if (newsType === 1) {
									// フリーレイアウト
									newsFileIcon = '';
									newsAnchor = `<a href="${urlHead}${newsDate.split('.')[0]}/${newsCatCode}/${_key}.html" class="mod-newsList-item-a">`;
								} else if (newsType === 2) {
									// 添付ファイル
									let dataFile = cmsNewsList[k].cf_n_attachment_file.replace(/\ /g, '');
									newsFileSize = bytesToSize(cmsNewsList[k].cf_n_attachment_file_size);
									newsFileExt = dataFile.split('.')[dataFile.split('.').length -1];
									newsAnchor = `<a href="/news/news_file/file/${dataFile}" target="_blank" class="mod-newsList-item-a">`;

									if (newsFileExt === 'pdf') {
										newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-pdf"></i>'+
											`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
									} else {
										newsFileIcon = '<span class="mod-newsList-item-file"><i class="icon icon-blank"></i>'+
											`<span class="mod-newsList-item-file_txt">[${newsFileSize}]</span></span>`;
									}

								} else if (newsType === 3) {
									// URL指定
									newsFileIcon = '';
									let newsUrl = cmsNewsList[k].cf_n_url;
									if (newsUrl.indexOf('https://') !== -1 || newsUrl.indexOf('http://') !== -1) {
										newsAnchor = `<a href="${newsUrl}" target="_blank" class="mod-newsList-item-a">`;
									} else {
										newsAnchor = `<a href="${newsUrl}" class="mod-newsList-item-a">`;
									}
								} else if (newsType === 5) {
									// テキスト表示
									newsFileIcon = '';
									newsAnchor = '<a href="" style="pointer-events:none;cursor:default;text-decoration:none;" class="mod-newsList-item-a">';
								}

								// ニュース描画
								newsHTMLcreate(newsAnchor, newsDate, newsCatTag, newsTit, newsFileIcon);
							}
						}
					}

				});
			}
		});

		// ＜セレクトボックス＆タブ＞---------------------------------------------------------------------------
		// タブのカレント
		if ($currentCat === $currentY) { // 「すべて」
			$('.mod-tabChange-btn[data-cat="all"]').addClass(a);
		} else {
			$tabItem.each((idx, ele) => {
				if ($(ele).attr('data-cat') === $currentCat) {
					$(ele).addClass(a);
				}
			});
		}
		// タブにURL生成
		$tabItem.each((idx, ele) => {
			let thisCat = $(ele).attr('data-cat');

			if (thisCat === 'all') {
				$(ele).find('.mod-tabChange-btn-a').attr('href', `${urlHead}${$currentY}/`)
			} else {
				$(ele).find('.mod-tabChange-btn-a').attr('href', `${urlHead}${$currentY}/${thisCat}/`);
			}
		});
		// 年号チェンジでページ遷移
		$selectY.on('change', (e) => {
			let thisY = $(e.target).val();
			if ($currentCat === 'all' || $currentCat === 'news') { // 「すべて」
				location.href = `${urlHead}${thisY}/`;
			} else { // 「すべて」以外
				location.href = `${urlHead}${thisY}/${$currentCat}/`;
			}
		});
	}

	// 関連情報出し分け
	$(`.news-idx-related-item[data-cat="${$currentCat}"]`).addClass(a);
	if (!$('.news-idx-related-item.is-active').length) {
		$('.news-idx-related').remove();
	} else {
		$('.news-idx-related .mod-head02').removeClass('di_none');
	}

})(window.jQuery, window.SLSD);
